import { inject, Injectable } from '@angular/core';

import { SessionBaseDto } from '../dtos/session/session-base.dto';
import { SessionBase } from '../models/session-base';

import { MapperFromDto } from './mappers';
import { UserBaseMapper } from './user-base.mapper';
import { DateMapper } from './date.mapper';

/** Session base mapper. */
@Injectable({ providedIn: 'root' })
export class SessionBaseMapper implements MapperFromDto<SessionBaseDto, SessionBase> {

	private readonly userBaseMapper = inject(UserBaseMapper);

	private readonly dateMapper = inject(DateMapper);

	/** @inheritdoc */
	public fromDto(dto: SessionBaseDto): SessionBase {
		return {
			client: this.userBaseMapper.fromDto(dto.from_user),
			description: dto.description,
			durationInMin: dto.duration,
			facilitator: this.userBaseMapper.fromDto(dto.to_user),
			id: dto.id,
			note: dto.note,
			sessionType: dto.session_type,
			status: dto.status,
			createdDate: this.dateMapper.fromDto(dto.created),
			completedDate: dto.completed_at ? this.dateMapper.fromDto(dto.completed_at) : null,
			rating: dto.rating,
			startDate: dto.started_at ? this.dateMapper.fromDto(dto.started_at) : null,
		};
	}
}
