import { z } from 'zod';

import { userBaseDtoSchema } from '../user-base.dto';
import { sessionStatusSchema } from '../../models/session-status';

/** Session base dto validation schema. */
export const sessionBaseDtoSchema = z.object({
	/** ID. */
	id: z.number(),

	/** Status. */
	status: sessionStatusSchema,

	/** User who requests a session. */
	from_user: userBaseDtoSchema,

	/** User who leads a session. */
	to_user: userBaseDtoSchema,

	/** Session type. */
	session_type: z.string(),

	/** Description. */
	description: z.string(),

	/** Notes. */
	note: z.string(),

	/** Session duration in minutes. */
	duration: z.number(),

	/** Created date. */
	created: z.string(),

	/** Completed date. */
	completed_at: z.string().nullish(),

	/** Consultation rating. */
	rating: z.number().nullable(),

	/** Date of consultation start. UTC string.  */
	started_at: z.string().nullable(),
});

/** Session base dto. */
export type SessionBaseDto = Readonly<z.infer<typeof sessionBaseDtoSchema>>;
