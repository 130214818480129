import { z } from 'zod';

/** Session status schema. */
export const sessionStatusSchema = z.enum(['requested', 'accepted', 'paid', 'declined', 'in_progress', 'completed', 'cancelled']);

/** Session status. */
export type SessionStatus = z.infer<typeof sessionStatusSchema>;

export namespace SessionStatus {

	const MAP_TO_READABLE: Record<SessionStatus, string> = {
		requested: 'Requested',
		accepted: 'Accepted',
		declined: 'Declined',
		paid: 'Paid',

		// TODO (Tien Luu): Create mapper for session status enum and update all in_progress to inProgress
		// eslint-disable-next-line @typescript-eslint/naming-convention
		in_progress: 'In Progress',
		completed: 'Completed',
		cancelled: 'Cancelled',
	};

	/** Available session status values. */
	export const ENUM = sessionStatusSchema.Enum;

	/**
	 * Map session status to human-readable.
	 * @param value Value.
	 */
	export function toReadable(value: SessionStatus): string {
		return MAP_TO_READABLE[value];
	}
}
